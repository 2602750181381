<template>
  <div>
    <v-sheet tile height="70" class="d-flex">
      <v-toolbar flat>
        <!-- <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
          Aujourd'hui
        </v-btn> -->
        <my-btn :filled="false" @click="setToday">Aujourd'hui</my-btn>
        <v-btn fab text small color="primary" @click="prev">
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn fab text small color="primary" @click="next">
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title class="primary--text" v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="auto" width="auto">
      <v-calendar
        ref="calendar"
        v-model="focus"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        color="primary"
        locale="fr-FR"
        :first-interval="7"
        :interval-count="12"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import FormulaireService from '../services/formulaire.service'

export default {
  props: {
    type: { type: String, required: true }
  },
  components: {
    'my-btn': () => import('./BasicBtn.vue')
  },
  data: () => ({
    focus: '',
    types: ['month', 'week', 'day', '4day'],
    mode: 'stack',
    weekday: [1, 2, 3, 4, 5, 6, 0],
    events: []
  }),

  mounted() {
    var events = []

    FormulaireService.getSessionDates()
      .then(response => {
        response.data.sessionDates.forEach(date => {
          var start = date.start
          var end = date.end
          events.push({
            name: 'Session',
            start: start,
            end: end,
            color: this.$vuetify.theme.themes.light.primary
          })
        })
        this.events = events
      })
      .catch()
  },
  methods: {
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    }
  }
}
</script>

<style>
.v-calendar-daily__scroll-area {
  overflow-y: hidden;
}

.v-calendar-weekly__week {
  height: 70px;
}
</style>
